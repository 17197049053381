import { Controller } from "@hotwired/stimulus"

// tom select の初期化
export default class extends Controller {
  connect() {

    let createSettings = {
      create: true,
      plugins: ['remove_button'],
      sortField: {
        field: "text",
        direction: "asc"
      }
    };

    let noCreateSettings = {
      create: false,
      plugins: ['remove_button'],
      sortField: {
        field: "text",
        direction: "asc"
      }
    }

    const createdAttrName = 'tom-select-created' // tom-selectを再生成するとエラーになるので、属性で印をつける
    for (let index = 0; index < 21; index++) {
      // リストを追加可能なselect box
      let select = document.getElementById(`tom-select-create-${index}`)
      if(select && !select.hasAttribute(createdAttrName)){
        new TomSelect(`#tom-select-create-${index}`, createSettings);
        select.setAttribute(createdAttrName, "true");
      }
      // リストを追加不能なselect box
      let noSelect = document.getElementById(`tom-select-no-create-${index}`)
      if(noSelect && !noSelect.hasAttribute(createdAttrName)){
        new TomSelect(`#tom-select-no-create-${index}`, noCreateSettings);
        noSelect.setAttribute(createdAttrName, "true");
      }
    }

    document.querySelectorAll('.tom-select-create').forEach((el)=>{
      if(!el.hasAttribute(createdAttrName) && el.tagName === 'SELECT') {
        console.log(el)
        new TomSelect(el, createSettings);
        el.setAttribute(createdAttrName, "true");
      }
    });

    document.querySelectorAll('.tom-select-no-create').forEach((el)=>{
      if(!el.hasAttribute(createdAttrName) && el.tagName === 'SELECT') {
        console.log(el)
        new TomSelect(el, noCreateSettings);
        el.setAttribute(createdAttrName, "true");
      }
    });
  }
}
