import { Controller } from "@hotwired/stimulus"

// 重量とサイズのフォームの入力制御
export default class extends Controller {
  static targets = [
    "commonWeight",
    "commonWidth",
    "commonHeight",
    "commonDepth",
    "isIndividualCheckbox"
  ]

  connect() {

    this.isIndividualCheckboxTarget.addEventListener("change", (event) => {
      // 個別に管理をチェックした場合に、個別フォームを有効に、共通フォームを無効にする
      let commonForms = document.getElementsByClassName("size-weight-form");
      let optionForms = document.getElementsByClassName("option-size-weight-form");
      if (event.target.checked) {
        for (let i = 0; i < commonForms.length; i++) {
          commonForms[i].disabled = true;
        }
        for (let i = 0; i < optionForms.length; i++) {
          optionForms[i].disabled = false;
        }
      } else {
        for (let i = 0; i < commonForms.length; i++) {
          commonForms[i].disabled = false;
        }
        for (let i = 0; i < optionForms.length; i++) {
          optionForms[i].disabled = true;
        }
      }
    })

    // 共通の入力フォームを更新したら個別のフォームにも同じ値を反映する

    // 重さ
    this.commonWeightTarget.addEventListener("input", (event) => {
      let optionWeightForms = document.getElementsByClassName("option-weight-form");
      for (let i = 0; i < optionWeightForms.length; i++) {
        optionWeightForms[i].value = event.target.value;
      }
    })

    // 幅
    this.commonWidthTarget.addEventListener("input", (event) => {
      let optionWidthForms = document.getElementsByClassName("option-size-width-form");
      for (let i = 0; i < optionWidthForms.length; i++) {
        optionWidthForms[i].value = event.target.value;
      }
    })

    // 奥行き
    this.commonDepthTarget.addEventListener("input", (event) => {
      let optionDepthForms = document.getElementsByClassName("option-size-depth-form");
      for (let i = 0; i < optionDepthForms.length; i++) {
        optionDepthForms[i].value = event.target.value;
      }
    })

    // 高さ
    this.commonHeightTarget.addEventListener("input", (event) => {
      let optionHeightForms = document.getElementsByClassName("option-size-height-form");
      for (let i = 0; i < optionHeightForms.length; i++) {
        optionHeightForms[i].value = event.target.value;
      }
    })
  }
}
