import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// tom select の初期化
export default class extends Controller {
  connect() {
    const sortableEl = document.getElementById('sort-list');
    console.log("sortableEl");
    Sortable.create(sortableEl, {
      animation: 200,
      handle: '.sort-handle',
      onEnd: function (_event) {
        // ソート番号を上書きする
        console.log("onEnd")
        console.log(_event)
        let items = sortableEl.querySelectorAll('.sortable-item');
        for (var i = 0; i < items.length; i++) {
          items[i].value = i + 1;
        }
      },
    });
  }
}
