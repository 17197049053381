// app/packs/controllers/chart_controller.js
import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class ChartController extends Controller {
  static ELEMENT_ID = 'YOUR_ELEMENT_ID';
  static DEFAULT_OPTIONS = { responsive: true, maintainAspectRatio: false };

  connect() {
    this.render();
  }

  render() {

    const ctx = document.getElementById('YOUR_ELEMENT_ID');

    const data = {
      labels: [65, 59, 80, 81, 56, 55, 40],
      datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    this.graph = new Chart(ctx, { type: 'line', data: data, options: this.options });
  }

  get ele() {
    return this._ele = this._ele || document.getElementById(ChartController.ELEMENT_ID);
  }

  get metric() {
    return this._metric = this._metric || this.element.dataset.metric;
  }

  get unit() {
    return this._unit = this._unit || this.element.dataset.unit;
  }

  // get metrics() {
  //   return this._metrics = this._metrics || JSON.parse(document.querySelector('[data-metrics-type]').dataset.metrics);
  // }

  get options() {
    return ChartController.DEFAULT_OPTIONS;
  }

  get data() {
    return { labels: this.labels, datasets: this.datasets };
  }

  get labels() {
    return this._labels = this._labels || this.metrics.map((m) => new Date(m.updated_at).toDateString());
  }

  get datasets() {
    return [{
      label: `${this.unit}`,
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }];
  }
}
