import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    function infoToast(copy_text) {
      toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "showDuration": "200",
        "hideDuration": "1200",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "linear",
        "hideEasing": "swing",
        "showMethod": "slideDown",
        "hideMethod": "hide",
      }
      toastr["info"](`クリップボードにコピーしました。${copy_text}`);
    }

    let clipboard = new ClipboardJS('.to_clipboard_btn');
    clipboard.on('success', function(e) {
      console.info('copies clipboard');
      console.info('Text:', e.text);
      infoToast(e.text)
      e.clearSelection();
    });
  }
}
